import {useAppStore} from "~/store/appStore";
const appStore = useAppStore()

export default defineNuxtRouteMiddleware(async (to, from) => {
    const readCookies = async () => {
        return await $fetch('/api/readCookies')
    }

    const infoCar = async () => {
        const cookies = await readCookies()
        return cookies.infoCar
    }
    const getInfoCarInCookies = await infoCar()
    appStore.infoCar.actualKType = (getInfoCarInCookies.actualKType === undefined) ? '' : getInfoCarInCookies.actualKType
    appStore.infoCar.actualImmat = (getInfoCarInCookies.actualImmat === undefined) ? '' : getInfoCarInCookies.actualImmat
})